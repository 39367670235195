import React from "react";
import Contacts from "../components/contacts";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Contatti = () => (
  <Layout>
    <SEO title="Contatti" />
    <Contacts />
  </Layout>
);

export default Contatti;
